import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { remove, increment, decrement, clearcart } from "../store/cartSlice";

const Cartitems = () => {
  const items = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const handleRemove = (productId) => {
    dispatch(remove(productId));
  };

  const handleDecrement = (product) => {
    dispatch(decrement(product));
  };

  const handleIncrement = (product) => {
    dispatch(increment(product));
  };

  const handleClearcart = () => {
    dispatch(clearcart());
  };

  const handleBuynow = () => {
    setPopup(true);
  };

  const totalPrice = items.reduce(
    (acc, product) => acc + product.price * product.quantity,
    0
  );

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState();
  const [popup,setPopup] = useState(false);

  const handleSubmit = (event) => {
    setPopup(false);
    event.preventDefault();
    alert(`Hey ${name}, Thanks for the Order. Your Order will reach you soon.`)
    dispatch(clearcart());
  };

  return (
    <>
      <div className={popup? "w-full md:py-8 mt-8 md:mt-0 fixed flex justify-center z-20":"w-full md:py-8 mt-8 md:mt-0 fixed hidden justify-center z-20"}>
        <form onSubmit={handleSubmit} className="grid bg-gray-900 p-2 rounded">
          <span onClick={()=>(setPopup(false))} type='button' className="flex justify-end text-gray-400 cursor-pointer hover:text-gray-200 active:text-white">X</span>
          <label className="leading-7 text-sm text-gray-400">
            Enter your name:
            <input
            className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              name="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label className="leading-7 text-sm text-gray-400">
            Enter your email:
            <input
            className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="leading-7 text-sm text-gray-400">
            Enter your phone number:
            <input
            className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              name="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </label>
          <label className="leading-7 text-sm text-gray-400">
            Enter your address:
            <input
            className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              name="address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </label>
          <button type="submit" className="m-2 active:text-white active:bg-green-400 hover:text-gray-200 text-gray-400 button bg-indigo-500 min-h-[2rem] min-w-[8rem] rounded">Buy</button>
        </form>
      </div>
      <div className=" min-h-screen bg-gray-800 text-gray-400">
        <button
          onClick={() => handleClearcart()}
          className="m-2 active:text-white active:bg-red-400 hover:text-gray-200 text-gray-400 button bg-indigo-500 min-h-[2rem] min-w-[8rem]"
        >
          Clear Cart
        </button>
        <button
          onClick={() => handleBuynow()}
          className="m-2 active:text-white active:bg-green-400 hover:text-gray-200 text-gray-400 button bg-indigo-500 min-h-[2rem] min-w-[8rem]"
        >
          Buy Now
        </button>
        <p className="text-xl bold">
          You have {Array.from(items).length} different kinds of products in the
          Cart
        </p>
        <p className="text-2xl bold">Total Cost:${totalPrice}</p>
        <div className="flex flex-wrap justify-evenly">
          {items.map((product) => (
            <div
              key={product.id}
              className="flex flex-col border-2 border-solid rounded border-blue-200 border-opacity-5 w-5/6 lg:w-[30%] md:w-[45%] justify-evenly items-center p-4 m-2"
            >
              <img
                src={product.image}
                alt="product"
                className="object-contain h-48 w-48 border-[0.5em] border-indigo-300 rounded-lg"
              />
              <h3 className="w-48">{product.title}</h3>
              <h3>${product.price}</h3>
              <div className="flex flex-wrap">
                <span
                  onClick={() => handleDecrement(product)}
                  type="button"
                  className=" active:text-white hover:text-gray-200 button m-1 cursor-pointer button px-2 bg-indigo-500"
                >
                  -
                </span>
                <input
                  type="text"
                  value={product.quantity}
                  disabled
                  className=" w-14 m-1 bg-gray-200 text-gray-900 block text-center"
                />
                <span
                  onClick={() => handleIncrement(product)}
                  type="button"
                  className="active:text-white hover:text-gray-200 button m-1 cursor-pointer button px-2 bg-indigo-500"
                >
                  +
                </span>
              </div>
              <button
                onClick={() => handleRemove(product.id)}
                className="active:text-white hover:text-gray-200 button bg-indigo-500 border-indigo-400 border-[2px] min-h-[2rem] min-w-[8rem]"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Cartitems;
