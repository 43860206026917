import React, { useState } from "react";
import { useGetProductsQuery } from "../store/productApi";
// import { useSelector } from "react-redux";
import { add } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import "./addedToCart.css";

const Products = () => {
  const { data, error, isLoading } = useGetProductsQuery();

  // const cartItems = useSelector((state) => state.cart);

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  // const handleAdd = (product) => {
  //   if (cartItems.length != 0) {
  //    const existingProduct = cartItems.find(
  //       (item) => item.id === product.id
  //     );

  //     if (existingProduct?.quantity === existingProduct?.rating?.count) {
  //       setShow(false);
  //     } else {
  //       setShow(true);
  //       setTimeout(() => {
  //         setShow(false);
  //       }, 3000);
  //     }
  //   }
  //   else {
      
  //     dispatch(add(product));
  //     setShow(true);
  //     setTimeout(() => {
  //       setShow(false);
  //     }, 3000);
  //   }
  // };

  const handleAdd =(product)=>{
        dispatch(add(product));
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 1200);
  }

  if(isLoading)return("Loading...")

  return (
    <>
      <div className={show ? "addedToCart show" : "addedToCart"}>
        <span>✅ Added to Cart</span>
      </div>
      <div className="flex flex-wrap justify-center text-gray-400 bg-gray-900 z-10">
        {data.map((product) => (
          <div
            key={product.id}
            className="m-4 md:w-[30%] border-2 border-solid rounded border-blue-200 border-opacity-5"
          >
            <div className="h-full border-2 border-gray-800 rounded-lg overflow-hidden">
              <img
                className="mt-4 mx-auto rounded lg:h-48 md:h-36 w-3/4 object-contain object-center"
                src={product.image}
                alt="blog"
              />
              <div className="p-6">
                <h2 className="tracking-widest text-xs title-font font-medium text-gray-500 mb-1">
                  {product.category}
                </h2>
                <h1 className="title-font text-lg font-medium text-white mb-3">
                  ${product.price}
                </h1>
                <p className="leading-relaxed mb-3">{product.title}</p>
                <div className="flex items-center flex-wrap ">
                  <a
                    type="button"
                    onClick={() => handleAdd(product)}
                    className="active:text-indigo-50 hover:text-indigo-300 p-1 border-2 border-solid border-blue-200 rounded-md border-opacity-10 cursor-pointer text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0"
                  >
                    Add to Cart
                    <svg
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M5 12h14"></path>
                      <path d="M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Products;