import logo from './logo.svg';
import { useGetProductsQuery } from './store/productApi';
import Navbar from './components/Navbar';
import { Home,Cart,About,Contact } from './pages';

import { Routes, Route } from "react-router-dom";

function App() {
  
  const { data, error, isLoading } = useGetProductsQuery();

  if(isLoading) return 'Loading...'
  
  return (<>
  <Navbar/>
  <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path='/cart' element={<Cart/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/contact' element={<Contact/>}/>
  </Routes>
  </>);
}

export default App;
