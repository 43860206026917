const { createSlice } = require("@reduxjs/toolkit");

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    add(state, action) {
      const existingProduct = state.find(
        (product) => product.id === action.payload.id
      );
      if (existingProduct?.id === action.payload.id) {
        // The find() method returns the first value in the array, if an element in the array satisfies
        const updatedCart = state.map((curElem) => {
          if (action.payload.id === curElem.id) {
            if (curElem.quantity === curElem.rating.count) {
              alert(
                `We have ${curElem.rating.count} ${curElem.title}, so you can't add them more to your cart`
              );
              return { ...curElem };
            } else {
              return { ...curElem, quantity: curElem.quantity + 1 };
            }
          }
          return curElem;
        });
        state = updatedCart;
        return state;

      } else {
        const addedItem = action.payload;
        const updatedItem = { ...addedItem, quantity: 1 }; // it should be given from the api, but if not then only add this line
        state.push(updatedItem);
      }
    },
    remove(state, action) {
      return state.filter((product) => product.id !== action.payload);
    },
    increment(state, action) {
      const updatedCart = state.map((curElem) => {
        if (action.payload.id === curElem.id) {
          if (curElem.quantity === curElem.rating.count) {
            alert(
              `We have ${curElem.rating.count} ${curElem.title}, so you can't add them more to your cart`
            );
            return { ...curElem };
          } else {
            return { ...curElem, quantity: curElem.quantity + 1 };
          }
        }
        return curElem;
      });

      state = updatedCart;
      return state;
    },
    decrement(state, action) {
      const updatedCart = state
        .map((curElem) => {
          if (curElem.id === action.payload.id) {
            return { ...curElem, quantity: curElem.quantity - 1 };
          }
          return curElem;
        })
        .filter((curElem) => curElem.quantity !== 0);
      state = updatedCart;
      return state;
    },
    clearcart(state, action) {
      state = [];
      return state;
    },
    // gettotal(state, action) {  // didn't use that in redux because it was becoming almost impossible to export the totalPrice from here a good place
    //   // let totalPrice = 0;

    //   // state.forEach((product) => {
    //   //   totalPrice += product.price * product.quantity; // This forEach method is also valid but it takes more time than reduce
    //   // });

    //   // const totalPrice = state.reduce(
    //   //   (acc, product) => acc + product.price * product.quantity,
    //   //   0);
    // },
  },
});

export const { add, remove, increment, decrement, clearcart } =
  cartSlice.actions;
export default cartSlice.reducer;
