import React from 'react';
import './Navbar.css';
import {Link} from 'react-router-dom';
import logo from '../images/react-cart-low-resolution-logo-color-on-transparent-background.png'

const Navbar = () => {
  return (<>
  <input type="checkbox" id="toggle"/>

  <nav>

      <Link className="navbar-brand flex items-center" to='/'>
          <img src={logo} width='200' alt="logo" />
      </Link>

      <label className="navbar-toggler" htmlFor="toggle">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
      </label>

      <ul className="nav-list">
          <li className="nav-item">
            <Link to='/' className="nav-link">Home</Link>
          </li>
          <li className="nav-item">
            <Link to='/cart' className="nav-link">Cart</Link>
          </li>
          <li className="nav-item">
            <Link to='/about' className="nav-link">About</Link>
          </li>
          <li className="nav-item">
            <Link to='/contact' className="nav-link">Contact</Link>
          </li>
      </ul>

  </nav>
  </>)
}

export default Navbar